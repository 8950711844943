import React from "react"
import Layout from "../components/layout"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="max-w-screen-xl mx-auto my-12">
        <h2 className="purple text-4xl font-bold text-center">
          Privacy Policy for PMI Total Fitness
        </h2>
        <p className="py-2 main">
          At PMI Total Fitness Website, accessible from
          https://pmitotalfitness.com, one of our main priorities is the privacy
          of our visitors. This Privacy Policy document contains types of
          information that is collected and recorded by PMI Total Fitness
          Website and how we use it.
        </p>
        <p className="py-2 main">
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </p>
        <p className="py-2 main">
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in PMI Total Fitness Website. This policy is not
          applicable to any information collected offline or via channels other
          than this website.
        </p>
        <h5 className="dark py-2 text-xl">Consent</h5>
        <p className="py-2 main">
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>
        <h5 className="dark py-2 text-xl">Information we collect</h5>
        <p className="py-2 main">
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information.
        </p>
        <p className="py-2 main">
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, the contents
          of the message and/or attachments you may send us, and any other
          information you may choose to provide.
        </p>
        <p className="py-2 main">
          When you register for an Account, we may ask for your contact
          information, including items such as name, company name, address,
          email address, and telephone number.
        </p>
        <h5 className="dark py-2 text-xl">How we use your information</h5>
        <p className="py-2 main">
          We use the information we collect in various ways, including to:
        </p>
        <ul className="list-disc list-inside ml-4 purple">
          <li>Provide, operate, and maintain our webste</li>
          <li>Improve, personalize, and expand our webste</li>
          <li>Understand and analyze how you use our webste</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the webste, and for
            marketing and promotional purposes
          </li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>
        <h5 className="dark py-2 text-xl">Log Files</h5>
        <p className="py-2 main">
          PMI Total Fitness Website follows a standard procedure of using log
          files. These files log visitors when they visit websites. All hosting
          companies do this and a part of hosting services' analytics. The
          information collected by log files include internet protocol (IP)
          addresses, browser type, Internet Service Provider (ISP), date and
          time stamp, referring/exit pages, and possibly the number of clicks.
          These are not linked to any information that is personally
          identifiable. The purpose of the information is for analyzing trends,
          administering the site, tracking users' movement on the website, and
          gathering demographic information.
        </p>
        <h5 className="dark py-2 text-xl">
          Advertising Partners Privacy Policies
        </h5>
        <p className="py-2 main">
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of PMI Total Fitness Website.
        </p>
        <p className="py-2 main">
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on PMI Total Fitness Website,
          which are sent directly to users' browser. They automatically receive
          your IP address when this occurs. These technologies are used to
          measure the effectiveness of their advertising campaigns and/or to
          personalize the advertising content that you see on websites that you
          visit.
        </p>
        <p className="py-2 main">
          Note that PMI Total Fitness Website has no access to or control over
          these cookies that are used by third-party advertisers.
        </p>
        <h5 className="dark py-2 text-xl">Third Party Privacy Policies</h5>
        <p className="py-2 main">
          PMI Total Fitness Website's Privacy Policy does not apply to other
          advertisers or websites. Thus, we are advising you to consult the
          respective Privacy Policies of these third-party ad servers for more
          detailed information. It may include their practices and instructions
          about how to opt-out of certain options. You may find a complete list
          of these Privacy Policies and their links here: Privacy Policy Links.
        </p>
        <p className="py-2 main">
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites. What Are Cookies?
        </p>
        <h5 className="dark py-2 text-xl">
          CCPA Privacy Rights (Do Not Sell My Personal Information)
        </h5>
        <p className="py-2 main">
          Under the CCPA, among other rights, California consumers have the
          right to:
        </p>
        <p className="py-2 main">
          Request that a business that collects a consumer's personal data
          disclose the categories and specific pieces of personal data that a
          business has collected about consumers.
        </p>
        <p className="py-2 main">
          Request that a business delete any personal data about the consumer
          that a business has collected.
        </p>
        <p className="py-2 main">
          Request that a business that sells a consumer's personal data, not
          sell the consumer's personal data.
        </p>
        <p className="py-2 main">
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>
        <h5 className="dark py-2 text-xl">GDPR Data Protection Rights</h5>
        <p className="py-2 main">
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
        </p>
        <p className="py-2 main">
          The right to access – You have the right to request copies of your
          personal data. We may charge you a small fee for this service.
        </p>
        <p className="py-2 main">
          The right to rectification – You have the right to request that we
          correct any information you believe is inaccurate. You also have the
          right to request that we complete the information you believe is
          incomplete.
        </p>
        <p className="py-2 main">
          The right to erasure – You have the right to request that we erase
          your personal data, under certain conditions.
        </p>
        <p className="py-2 main">
          The right to restrict processing – You have the right to request that
          we restrict the processing of your personal data, under certain
          conditions.
        </p>
        <p className="py-2 main">
          The right to object to processing – You have the right to object to
          our processing of your personal data, under certain conditions.
        </p>
        <p className="py-2 main">
          The right to data portability – You have the right to request that we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </p>
        <p className="py-2 main">
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>
        <h5 className="dark py-2 text-xl">Children's Information</h5>
        <p className="py-2 main">
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>
        <p className="py-2 main">
          PMI Total Fitness Website does not knowingly collect any Personal
          Identifiable Information from children under the age of 13. If you
          think that your child provided this kind of information on our
          website, we strongly encourage you to contact us immediately and we
          will do our best efforts to promptly remove such information from our
          records.
        </p>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
